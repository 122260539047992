<template>
  <main class="view">
    <div class="col col-12 tabs-wrapper">
      <ul class="tab-controller" :class="{hidden: !showTabs, 'submenu-items': submenuItems}">
        <li class="tab-controller__item" :class="[tabView == 0 ? 'active' : 'non', Check(AdminRoles) && IsMainCompany() ? '--parent' : '']">
          <router-link class="item-link" :to="{ name: 'salary-report'}"> Salary report </router-link>
          <ul class="item-submenu">
            <!--
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'report-work'}"> Work </router-link>
            </li>
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'report-pay'}"> Pay </router-link>
            </li>
            -->
          </ul>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { Config } from '@/helpers/Config';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Salary',
    mixins: [
      Permissions, 
      Config, 
      BPA
    ],
    components: {},
    data() {
      return {
        tabView: 0,
        showTabs: true,
        submenuItems: false,
        RouteMapper: {'report': 0},
        AdminRoles: [/*0*/ 'admin'],
        route: null,
        dev: Config.dev
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    mounted() {
      this.showTabs = this.tabs.length > 1;
    },
    watch: {
      $route () {
        this.IsInRouteMapper();
      }
    },
    computed: {
      tabs() {
        return document.querySelectorAll('.tab-controller__item');
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        let name = this.route.split('-').pop();
        if (this.RouteMapper[name] !== null) {
          this.tabView = this.RouteMapper[name];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
  .tab-controller {
    .item-submenu {
      z-index: 1;
    }

    &[style*=--submenu-bg] .item-submenu__item a {
      color: white;
    }
  }
</style>